<template>
  <div class="box">
    <div style="text-align: center" class="font_size_25">产品介绍</div>
    <div class="content">
      <div>
        <img class="zhenxi" src="../assets/zhenxi.png" alt="" />
      </div>
      <div>
        <div class="zhenxiright">
          <div><img class="zxlogo" src="../assets/zxlogo.png" alt="" /></div>
          <div>
            <span class="font_size_24">斟兮</span>
            <br />
            <span class="font_size_24">酱香型白酒</span>
            <br />
            <span class="font_size_23">ZhenXi Maotai flavor Baijiu</span>
          </div>
        </div>
        <div class="zxword">
          <p class="font_size">斟兮，源自中国贵州仁怀茅台镇的传统佳酿。</p>
          <p class="font_size">
            该酒借助其严格的品控体系，结合古法工艺，成功打造出独一无二的酒质。
          </p>
          <p class="font_size">
            斟兮酒最显著的特点是其浓郁的茅香和丝滑柔顺的口感，这种香气和口感的平衡展现了其深厚的制酒工艺。
          </p>
          <p class="font_size">
            酒体中，酸酯的过渡呈现得恰到好处，最大限度保留了纯粮特质。
          </p>
          <p class="font_size">
            斟兮不仅是一款酒，更是一种文化的传承与创新，其“好酒相伴，通达天下”的使命感，代表着中国传统白酒在日新月异的今天与时俱进。
          </p>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="three">
        <div class="left">
          <img src="../assets/wenhua.png" alt="" />
          <img src="../assets/pinzhi.png" alt="" />
        </div>
        <div class="threeright">
          <div class="right">斟兮-核心竞争力</div>
          <div class="rightword">文化</div>
          <div class="rightword">品质</div>
          <!-- <img class="zxbj" src="../assets/zxbj.png" alt="" /> -->
        </div>
      </div>
    </div>

    <div class="content">
      <div class="contentleft">
        <div style="margin: 20px; 30px">
          <div>
            <span class="font_size_24">斟兮-品质</span>
          </div>

          <p class="font_size">
            <span class="font_size_15">纯粮酿造</span>
            <br />
            酿造所需要的高粱均为产自本地的有机糯高粱，生长于赤水河流域。
          </p>

          <p class="font_size">
            <span class="font_size_15">古法工艺</span>
            生产遵循传统的12987酱酒的酿造工艺，其特点是以工艺复杂，耗费时间，出酒率低，成本高，酒质好而著称。
          </p>

          <p class="font_size">
            <span class="font_size_15">赤水之韵</span>
            酿酒所用的水源自赤水河，其水质清澈透明 、入口微甜，降解物少
            、硬度适中
            、PH值7.5左右、酸碱度适中，并且富含多种矿物质和有益微量元素。
          </p>

          <p class="font_size">
            <span class="font_size_15">怡醉无忧</span>
            酸酯含量平衡，融合度好
          </p>
        </div>
      </div>
      <div class="contentright">
        <img class="zhenxi" src="../assets/tarit.png" alt="" />
      </div>
    </div>

    <div class="culturalcontent">
      <div class="culturalthree">
        <div class="culturalleft">
          <!-- <img src="../assets/cultural1.jpg" alt="" /> -->
          <!-- <img src="../assets/cultural2.png" alt="" /> -->
          <img src="../assets/cultural4.png" alt="" />
        </div>
        <div class="culturalright">
          <div class="font_size_24">斟兮-文化</div>
          <br />
          <div class="font_size_15">“斟兮”出处</div>
          <br />
          <div class="font_size_23">明万历十五年内府刊本，1305页91章。</div>
          <br />
          <div>
            明实录记载朱元璋祈福三皇五帝赞文：
            《大明会典》·大明会典卷之九十一，祭历代帝王乐章， 酒斟兮礼明。
          </div>
          <div>诸帝熙和兮悦情。&emsp; 百职奔走兮满庭。</div>
          <div>陈笾豆兮数重。&emsp; 谨亚献兮愿成。</div>
        </div>
      </div>
    </div>
    <div class="head-label">
      <div class="word">
        <div>
          <span class="name">赵时（历史学博士）</span>
          <span class="originator">创始人</span>
          <span class="originatorname">赵时</span>
        </div>
        <br />

        <div>
          <p>
            在她的研究生涯中，深入研究汉画像石，被其中描绘的日常生活场景和仪式活动深深吸引。特别是其中关于酿酒和宴饮的场景，激发了她对古代酿酒文化的好奇心。她开始探索汉代人民酿造和享用酒的方式，这一兴趣最终引领她走上了创立自己的酒类品牌之路。
          </p>
          <p>
            在深入研究古法酿造工艺的基础上，创立了“斟兮”品牌。“斟兮”出自于《大明会典》·大明会典卷之九十，祭历代帝王乐章的第一句：“酒斟兮礼明”，旨在把酒斟满，是最高的礼仪。在斟兮酒的设计中，她巧妙地融入了九尾狐和红缨糯米高粱的元素。酒瓶上精细的烤花不仅展示了艺术的精髓，也体现了对历史的深刻理解和尊重。
          </p>
          <p>
            九尾狐象征着祥瑞与盛世太平，而红缨糯米高粱则是丰收和吉祥的标志，同时也是酱香酒的原始材料。这些设计元素在斟兮酒的包装上交相辉映，不仅表达了对历史文化传承的尊敬，也传达了对现代审美的追求。
          </p>
          <p>
            她说：“知识美学引领生活美学，在高科技时代弘扬传统文化，体验美好生活，因珍惜而斟兮。”
          </p>
        </div>
      </div>
      <div class="img"><img src="../assets/图片1.jpg" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZxProducts',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
.head-label {
  width: 100%;
  height: auto;
  background-color: #efefef;
  background-size: 100% 100%; /* 设置宽度为100%，高度自适应 */
  background-repeat: no-repeat;
  background-image: url('../assets/soild.png');
  background-position: 100% 100%;
  display: flex;
  flex-wrap: wrap; /* 允许内容换行 */
  justify-content: space-between; /* 为子元素间添加一些空间 */
  margin: 50px 0px;
}

.word {
  //  width:60%; /* 在大屏幕上占据60%的宽度 */
  width: 60%;
  margin-top: 3%;
  margin-left: 50px;
  /* 如果需要，可以添加其他样式，例如 padding 或 margin */
}
.name {
  font-weight: 700;
  font-size: 40px;
  margin: 100px 0;
}
.originator {
  font-weight: 600;
  font-size: 28px;
  margin: 100px 0px;
  margin-left: 50px;
}
.originatorname {
  font-weight: 600;
  font-size: 28px;
  margin: 100px 0px;
  margin-left: 20px;
}

.img {
  position: relative;
  width: 30%;
  height: 0; /* 这将创建一个高度为0的容器，以确保 padding-top 百分比基于容器宽度而不是高度 */
  padding-top: 30%; /* 这将创建一个正方形容器，可以根据需要调整百分比 */
  margin: 0 auto;
}

.img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: auto;
}

.words {
  flex-basis: 70%; /* 在大屏幕上占据60%的宽度 */
  display: flex;
  padding: 120px 50px;
  /* 如果需要，可以添加其他样式，例如 padding 或 margin */
}

.top {
  font-weight: 700;
  font-size: 34px;
}

.content-one {
  font-weight: 600;
  font-size: 20px;
}

.box {
  width: 90%;
  margin: 0 auto;
}
.content {
  display: flex; /* 使用 Flex 布局 */
  margin: 50px 0;
  .contentleft {
    width: 50%;
  }
  .contentright {
    width: 50%;
  }
}
.culturalcontent {
  display: flex; /* 使用 Flex 布局 */
  margin: 50px 0;
}
.zhenxiright {
  display: flex; /* 使用 Flex 布局 */
  margin-top: 10%;
}
.zhenxi {
  width: 100%;
}
.font_size_25 {
  margin: 80px 0;
  font-size: 2.5em;
}
.font_size_24 {
  color: #595959;
  font-size: 2em;
  font-weight: 700;
}
.font_size_15 {
  color: #595959;
  font-size: 1.5em;
  font-weight: 700;
}
.font_size_23 {
  color: #595959;
  font-size: 0.5em;
}
.font_size {
  color: #595959;
  font-weight: 700;
}
.zxlogo {
  margin: 10px 10px;
  width: 40px;
}
.three {
  display: flex;
  width: 100%;
  flex-basis: auto; /* 使元素宽度自动调整 */
}
.culturalthree {
  display: flex;
  width: 100%;
  flex-basis: auto; /* 使元素宽度自动调整 */
}
.left {
  width: 50%;

  display: flex;
}
.threeright {
  width: 50%;
  text-align: center;
  margin-top: 10%;
  .right {
    // width:  30%;
    color: #595959;
    font-weight: 700;
    font-size: 2em;
    margin: 20px 0px;
  }
  .rightword {
    position: relative;
    font-size: 3em;
    color: #595959;
    margin: 5px 0px;
    font-weight: 700;
  }
  .zxbj {
    width: 50px;
    position: relative;
  }
}
.left img {
  width: 50%;
}
.culturalright {
  margin: 0 auto;
  width: 30%;
  color: #595959;
  font-weight: 700;
  margin-top: 10%;
}

.culturalleft {
  width: 50%;
}
.culturalleft img {
  width: 100%;
}
@media screen and (max-width: 450px) {
  .img {
    position: relative;
    width: 100%;
    height: 0; /* 这将创建一个高度为0的容器，以确保 padding-top 百分比基于容器宽度而不是高度 */
    padding-top: 50%; /* 这将创建一个正方形容器，可以根据需要调整百分比 */
    margin: 0 auto;
  }

  .img img {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: auto;
  }

  .head-label {
    width: 100%;
    height: auto;
    background-color: transparent;
    background-size: 100% 100%; /* 设置宽度为100%，高度自适应 */
    background-repeat: no-repeat;
    background-image: url('../assets/soild.png');
    background-position: 100% 100%;
  }

  .name {
    font-weight: 700;
    font-size: 26px;
    margin: 20px 0;
  }

  .originator {
    font-weight: 600;
    font-size: 20px;
    margin: 20px 0px;
    margin-left: 5px;
  }
  .originatorname {
    font-weight: 600;
    font-size: 20px;
    margin: 20px 0px;
    margin-left: 5px;
  }
  .head-label {
    width: 100%;
    height: auto;
    background-size: 0%0%; /* 设置宽度为100%，高度自适应 */
    background-repeat: no-repeat;
    // background-image: url('../assets/gray.png');
    background-position: 0% 0%;
  }
  .head-label {
    flex-direction: column; /* 改变主轴方向为垂直 */
  }
  .word {
    // flex-basis: 70%; /* 在大屏幕上占据60%的宽度 */
    padding: 0px 10px;
    width: 90%;
    margin: 0 auto;
  }

  .content {
    flex-direction: column;
    .contentleft {
      width: 100%;
    }
    .contentright {
      width: 100%;
    }
  }
  .zhenxiright {
    width: 200px;
    padding: 30px 0;
    margin: 0 auto;
  }
  .culturalright {
    margin: 0 auto;
    width: 90%;
    color: #595959;
    font-weight: 700;
  }
  .zxlogo {
    width: 30px;
  }

  .content {
    flex-direction: column;
    .contentleft {
      width: 100%;
    }
    .contentright {
      width: 100%;
    }
  }
  .font_size_24 {
    color: #595959;
    font-size: 1.5em;
    font-weight: 700;
  }

  .box {
    width: 100%;
    margin: 0 auto;
  }
  .zhenxi {
    margin: 0 auto;
  }
  .top {
    font-weight: 700;
    font-size: 30px;
  }
  .name {
    font-weight: 700;
    font-size: 26px;
    margin: 20px 0;
  }

  .three {
    flex-direction: column;
  }
  .culturalthree {
    flex-direction: column;
  }
  .culturalleft {
    width: 100%;
  }
  .left {
    width: 100%;
  }
  .left img {
    width: 50%;
  }
  .culturalleft img {
    width: 100%;
    height: auto;
    background-size: 0%0%; /* 设置宽度为100%，高度自适应 */
  }
  .threeright {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    .right {
      // width:  30%;
      color: #595959;
      font-weight: 700;
      font-size: 2em;
      margin: 20px 0px;
    }
    .rightword {
      position: relative;
      font-size: 1.5em;
      color: #595959;
      font-weight: 700;
    }
  }
  .zxbj {
    width: 20px;
  }
}

@media screen and (min-width: 451px) and (max-width: 1200px) {
  .img img {
    width: 50%;
  }
  .head-label {
    width: 100%;
    height: auto;
    background-color: #efefef;
    background-size: 100% 100%; /* 设置宽度为100%，高度自适应 */
    background-repeat: no-repeat;
    background-image: url('../assets/soild.png');
    background-position: 100% 100%;
  }
  .name {
    font-weight: 700;
    font-size: 26px;
    margin: 20px 0;
  }

  .originator {
    font-weight: 600;
    font-size: 20px;
    margin: 20px 0px;
    margin-left: 5px;
  }
  .originatorname {
    font-weight: 600;
    font-size: 20px;
    margin: 20px 0px;
    margin-left: 5px;
  }
  .head-label {
    width: 100%;
    height: auto;
    background-size: 0%0%; /* 设置宽度为100%，高度自适应 */
    background-repeat: no-repeat;
    // background-image: url('../assets/gray.png');
    background-position: 0% 0%;
  }
  .head-label {
    flex-direction: column; /* 改变主轴方向为垂直 */
  }
  .word {
    // flex-basis: 70%; /* 在大屏幕上占据60%的宽度 */
    padding: 0px 10px;
    width: 90%;
    margin: 0 auto;
  }

  .content {
    flex-direction: column;
    .contentleft {
      width: 100%;
    }
    .contentright {
      width: 100%;
    }
  }
  .zhenxiright {
    width: 200px;
    padding: 30px 0;
    margin: 0 auto;
  }
  .culturalright {
    margin: 0 auto;
    width: 90%;
    color: #595959;
    font-weight: 700;
  }
  .zxlogo {
    width: 30px;
  }

  .content {
    flex-direction: column;
    .contentleft {
      width: 100%;
    }
    .contentright {
      width: 100%;
    }
  }
  .font_size_24 {
    color: #595959;
    font-size: 1.5em;
    font-weight: 700;
  }

  .box {
    width: 100%;
    margin: 0 auto;
  }
  .zhenxi {
    margin: 0 auto;
  }
  .top {
    font-weight: 700;
    font-size: 30px;
  }

  .three {
    flex-direction: column;
  }
  .culturalthree {
    flex-direction: column;
  }
  .culturalleft {
    width: 100%;
  }
  .left {
    width: 100%;
  }
  .left img {
    width: 50%;
  }
  .culturalleft img {
    width: 100%;
    height: auto;
    background-size: 0%0%; /* 设置宽度为100%，高度自适应 */
  }
  .threeright {
    width: 100%;
    text-align: center;
    margin-top: 10%;
    .right {
      // width:  30%;
      color: #595959;
      font-weight: 700;
      font-size: 2em;
      margin: 20px 0px;
    }
    .rightword {
      position: relative;
      font-size: 1.5em;
      color: #595959;
      font-weight: 700;
    }
  }
  .zxbj {
    width: 20px;
  }
}
</style>
