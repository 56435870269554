// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

// Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]

// const router = new VueRouter({
//   routes
// })

// export default router
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/note',
    name: 'note',
    component: () => import('../views/note.vue'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/products.vue'),
  },
  {
    path: '/model',
    name: 'model',
    component: () => import('../views/business model.vue'),
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/Merchandising',
    name: 'Merchandising',
    component: () => import('../views/Merchandising.vue'),
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import('../views/cooperation.vue'),
  },
]

const router = new VueRouter({
  routes,
})

export default router
