<template>
  <div>
    <div class="box">
      <div>
        <div style="text-align: center; margin: 100px 0px" class="font_size_25">
          线上自媒体/购买渠道
        </div>
        <div class="buy">
          <div class="card">
            <el-card style="height: 250px" shadow="hover">
              <p class="font_size_15">杂志期刊</p>
              <!-- <p
                class="font_size"
                @mouseover="showMagazine"
                @mouseout="hideMagazine"
              >
                大众考古
              </p> -->
              <p class="font_size">
                <a
                  class="font_size"
                  href="https://mp.weixin.qq.com/s/QpM8UoyKvByWc99bMn1YPQ"
                  target="_blank"
                  >大众考古</a
                >
              </p>
              <!-- <div class="show" v-show="showMagazineContent">展示大众考古</div> -->
              <!-- <p
                class="font_size"
                @mouseover="showPublicAccount"
                @mouseout="hidePublicAccount"
              >
                斟兮公众号
              </p> -->
              <p class="font_size">
                <a
                  class="font_size"
                  href="https://mp.weixin.qq.com/s/XXsXEWnxsUUSo9uqsbPY2g"
                  target="_blank"
                  >斟兮公众号</a
                >
              </p>
              <!-- <div class="show" v-show="showPublicAccountContent">
              <img src="../assets/wx.png" alt="">
              </div> -->
              <!-- <p class="font_size" @mouseover="showWeibo" @mouseout="hideWeibo">
                斟兮微博
              </p> -->
              <p class="font_size">
                <a
                  class="font_size"
                  href="https://weibo.com/u/7464287747"
                  target="_blank"
                >
                  斟兮微博</a
                >
              </p>
              <!-- <div class="show" v-show="showWeiboContent">展示斟兮微博</div> -->
            </el-card>
          </div>
          <div class="card">
            <el-card style="height: 250px" shadow="hover">
              <p class="font_size_15">短视频平台</p>
              <!-- <p class="font_size">抖音</p> -->
              <p class="font_size">
                <a
                  class="font_size"
                  href="https://www.douyin.com/user/MS4wLjABAAAA5Ks0y6EeOXrg_1SBzLaPzD_tR68ZzfCYDc8Penhdkw0"
                  target="_blank"
                  >抖音</a
                >
              </p>
              <p class="font_size">微信视频号</p>
            </el-card>
          </div>
          <div class="card">
            <el-card style="height: 250px" shadow="hover">
              <p class="font_size_15">高质量问答社区</p>
              <p class="font_size">知乎</p>
              <!-- <p class="font_size">小红书</p> -->
              <p class="font_size">
                <a
                  class="font_size"
                  href="https://www.xiaohongshu.com/user/profile/5f043a090000000001000bae"
                  target="_blank"
                  >小红书</a
                >
              </p>
              <p class="font_size">百度百科</p>
            </el-card>
          </div>
          <div class="card">
            <el-card style="height: 250px" shadow="hover">
              <p class="font_size_15">购买渠道</p>
              <p class="font_size">斟兮微信小程序</p>
              <p class="font_size">
                <a
                  class="font_size"
                  href="https://item.jd.com/10088255129954.html"
                  target="_blank"
                  >斟兮京东旗舰店</a
                >
              </p>
              <p class="font_size">
                <a
                  class="font_size"
                  href="https://www.douyin.com/user/MS4wLjABAAAA5Ks0y6EeOXrg_1SBzLaPzD_tR68ZzfCYDc8Penhdkw0"
                  target="_blank"
                  >斟兮抖音旗舰店</a
                >
              </p>
            </el-card>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div class="bottom">
      <br /><br /><br />
      <div class="bottomcenter">
        <div style="margin-top: 60px">
          <img style="width: 120px" src="../assets/wx.png" alt="" />
          <img style="width: 120px" src="../assets/XCX.png" alt="" />
          <!-- <img style="width: 120px" src="../assets/KF.png" alt="" /> -->
        </div>
        <div style="margin-top: 20px" class="bottomcenterleft">
          <p class="font_size">
            邮箱：zhenxi@zhenxitech.top
            <a
              target="_blank"
              href="http://mail.qq.com/cgi-bin/qm_share?t=qm_mailme&email=6pCCj4SSg6qQgo_EkoOej4mCxJ6Fmg"
              style="text-decoration: none"
              ><img
                src="http://rescdn.qqmail.com/zh_CN/htmledition/images/function/qm_open/ico_mailme_12.png"
            /></a>
          </p>
          <!-- <p class="font_size">商务合作/区域代理：15951877776</p> -->
          <p class="font_size">官方联系电话：400-857-8899</p>

          <a
            target="_blank"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="text-decoration: none"
          >
            <p class="font_size">苏ICP备2024062668号-2</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZxBusinessModel',

  data() {
    return {
      showMagazineContent: false,
      showPublicAccountContent: false,
      showWeiboContent: false,
    }
  },

  mounted() {},

  methods: {
    showMagazine() {
      this.showMagazineContent = true
    },
    hideMagazine() {
      this.showMagazineContent = false
    },
    showPublicAccount() {
      this.showPublicAccountContent = true
    },
    hidePublicAccount() {
      this.showPublicAccountContent = false
    },
    showWeibo() {
      this.showWeiboContent = true
    },
    hideWeibo() {
      this.showWeiboContent = false
    },
  },
}
</script>

<style lang="scss" scoped>
.show {
  position: absolute;
  left: 12%;
  text-align: center;
  width: 200px;
  line-height: 40px;
  height: 40px;
  background-color: #fff;
}
.bottom {
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #eaeaea;
  height: 380px;
  display: flex;
}
.card {
  margin: 50px 0px;
  text-align: center;
  width: 20%;
  height: 250px;
}
.font_size_15 {
  color: #595959;
  font-size: 1.5em;
  font-weight: 700;
}

.font_size {
  color: #595959;
  font-weight: 700;
}
.box {
  width: 90%;
  margin: 0 auto;
}
.bottomcenter {
  margin: 0 auto;
  //display: flex;
}
.bottomcenterleft {
  // display: flex;
  // flex-direction: column;
  align-items: center; /* 将内容垂直居中 */
  justify-content: center; /* 将内容水平居中 */
}
.font_size_25 {
  margin: 30px 0;
  font-size: 2.5em;
}
.font_size_24 {
  color: #595959;
  font-size: 2em;
  font-weight: 700;
}
.bgimg {
  display: flex;
  // background-color: #595959;
  width: 100%;
  margin: 0 auto;
}

.bgimgleft {
  width: 65%;
}
.bgimgleft img {
  width: 100%;
  margin: 10px;
}

.bgimgright {
  flex-direction: column; /* 让子元素垂直排列 */
  width: 40%;

  // background-color: palegoldenrod;
}
.bgimgright img {
  float: right;
  margin: 15px;
  width: 87%;
}
.buy {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-device-width: 1200px) {
  .bottom {
    flex-direction: column; /* 让子元素垂直排列 */
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #eaeaea;
    height: 600px;
  }
  .card {
    width: 100%;
    text-align: center;
    padding: 0px 0px;
    height: 250px;
    margin: 20px 0px;
  }
  .bottomcenter {
    flex-direction: column; /* 让子元素垂直排列 */
  }
  .buy {
    flex-direction: column; /* 让子元素垂直排列 */
  }
  .bgimg {
    flex-direction: column; /* 让子元素垂直排列 */
  }
  .bgimgleft {
    width: 100%;
  }
  .bgimgleft img {
    width: 100%;
    margin: 0px 0px;
  }
  .bgimgright {
    flex-direction: column; /* 让子元素垂直排列 */
    width: 100%;
  }
  .bgimgright img {
    margin: 0px 0px;
    width: 100%;
  }
}
</style>
