<template>
  <div>
   
  </div>
</template>

<script>
export default {
  name: 'Business',

  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped></style>
