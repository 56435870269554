<template>
  <div>
    <div>
      <el-carousel :height="computeHeight">
        <el-carousel-item v-for="(item, index) in sliders" :key="index">
          <img :src="item.img" class="slider_img" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box">
      <div class="solve">
        <div style="text-align: center" class="font_size_25">斟兮</div>
        <br />
        <div style="text-align: center" class="font_size_24">
          -文化属性品质酒
        </div>
      </div>

      <!-- 盒子 -->
      <div class="border_parent">
        <div class="border_content">
          <div class="border_content_one">以品质为基础</div>
          <div class="border_content_one">以文化为传承</div>
          <div class="border_content_one">以创新为己任</div>
        </div>
        <div class="border_content">
          <div class="border_content_two">
            <p>企业文化： 因珍惜而斟兮</p>
          </div>
          <div class="border_content_two">
            <p>企业使命：好酒相伴，通达天下</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZxNote',

  data() {
    return {
      sliders: [],
    }
  },
  computed: {
    //计算高度
    computeHeight() {
      let screenHeight = document.body.clientWidth
      if (screenHeight > 1200) {
        return '570px'
      } else {
        return '150px'
      }
    },
  },
  mounted() {
    this.sliders = [
      {
        img: require('../assets/banner1.jpg'),
      },
      {
        img: require('../assets/banner2.jpg'),
      },
      {
        img: require('../assets/banner3.jpg'),
      },
      // {
      //   img: require('../assets/Snipaste_2024-01-11_19-19-40.png'),
      // },
    ]
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
.head-label {
  display: flex;
  flex-wrap: wrap; /* 允许内容换行 */
  justify-content: space-between; /* 为子元素间添加一些空间 */
}

.word {
  flex-basis: 70%; /* 在大屏幕上占据60%的宽度 */
  padding: 50px 50px;
  /* 如果需要，可以添加其他样式，例如 padding 或 margin */
}

.img {
  margin: 0 auto;
  float: right;
  padding: 50px 30px;
  flex-basis: 15%; /* 在大屏幕上占据40%的宽度 */
  /* 如果需要，可以添加其他样式，例如 padding 或 margin */
  /* 确保图片不超过容器的大小 */
  max-width: 100%;
  height: auto;
}
.head-label {
  width: 100%;
  height: auto;
  background-color: #efefef;
  background-size: 100% 100%; /* 设置宽度为100%，高度自适应 */
  background-repeat: no-repeat;
  background-image: url('../assets/soild.png');
  background-position: 100% 100%;
}
.font_size_25 {
  margin: 30px 0;
  font-size: 2.5em;
}
.font_size_24 {
  font-size: 1.5em;
}

.slider_img {
  width: 100%;
  height: auto;
  background-size: 0%0%; /* 设置宽度为100%，高度自适应 */
}
.border_content {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  line-height: 200px;
  padding: 30px 0;
}
.border_content_one {
  width: 30%;
  text-align: center;
  background-color: #f1f1f1;
  font-size: 22px;
  border-radius: 20px;
}

.border_content_two {
  width: 45%;
  text-align: center;
  background-color: #f1f1f1;
  font-size: 22px;
  border-radius: 20px;
}
.border_parent {
  width: 90%;
  margin: auto;
}
.border_content_two_span {
  font: 600;
}
.top {
  font-weight: 700;
  font-size: 34px;
}
.name {
  font-weight: 700;
  font-size: 30px;
  margin: 40px 0;
}
.content-one {
  font-weight: 600;
  font-size: 20px;
}
.box {
  width: 90%;
  margin: 0 auto;
}
@media screen and (max-device-width: 1200px) {
  .top {
    font-weight: 700;
    font-size: 30px;
  }
  .name {
    font-weight: 700;
    font-size: 26px;
    margin: 20px 0;
  }
  .head-label {
    width: 100%;
    height: auto;
    background-size: 0%0%; /* 设置宽度为100%，高度自适应 */
    background-repeat: no-repeat;
    // background-image: url('../assets/gray.png');
    background-position: 0% 0%;
  }
  .head-label {
    flex-direction: column; /* 改变主轴方向为垂直 */
  }

  .word,
  .img {
    flex-basis: auto; /* 使元素宽度自动调整 */
  }

  .img {
    /* 可选：在上下布局时调整图片大小 */
    max-width: 80%;
    margin: 0 auto; /* 居中图片 */
  }
  .border_content {
    flex-direction: column; /* 在小屏幕上改为垂直排列 */
  }
  .border_content_one,
  .border_content_two {
    width: 100%;
    text-align: center;
    background-color: #f2f6fc;
    border-radius: 20px;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .slider_img {
    width: 100%;
    object-fit: cover;
  }

  .border_parent {
    display: flex;
    width: 90%;
    margin: auto;
    height: auto;
    flex-direction: column;
  }
  .box {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
