<template>
  <div>
    
  </div>
</template>

<script>
export default {
  name: 'Contact',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>