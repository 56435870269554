// import request from '@/utils/request'

// export function addbusiness(data) {
//   return request({
//     url: '/home/demand/apply',
//     method: 'get',
//     data: data,
//   })
// }
import request from '@/utils/request'

//获取列表
// {current}/{limit}

export function addbusiness(data) {
  return request({
    url: '/home/demand/apply',
    method: 'post',
    data: data,
  })
}
