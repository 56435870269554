<template>
  <div>
    <div class="home-view">
      <div class="logo">
        <img class="logoimg" src="../assets/logo.jpg" alt="" />
        <p class="title">斟兮科技</p>
      </div>
      <div class="tab">
        <div
          class="tab-item"
          :class="{ active: activeTab === 'intro' }"
          @click="scrollTo('intro')"
        >
          斟兮简介
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'product' }"
          @click="scrollTo('product')"
        >
          产品介绍
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'business' }"
          @click="scrollTo('business')"
        >
          斟兮线下品鉴店
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'Contact' }"
          @click="scrollTo('Contact')"
        >
          联系我们
          <div v-if="show" class="mine">
            <p
              class="tab-item2"
              :class="{ active: activeTab === 'Merchandising' }"
              @click="scrollTo('Merchandising')"
            >
              销售购买
            </p>
            <p class="tab-item2" @click="dialogVisible = true">商务合作</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div id="intro" class="content-section">
        <note></note>
      </div>
      <div id="product" class="content-section">
        <products></products>
      </div>
      <div id="business" class="content-section">
        <model></model>
      </div>
      <div id="Contact" class="content-section">
        <Contact></Contact>
      </div>
      <div id="Merchandising" class="content-section">
        <Merchandising></Merchandising>
      </div>
    </div>

    <el-dialog
      title="商务合作"
      :visible.sync="dialogVisible"
      :width="computewidth"
      :before-close="handleClose"
      class="dialog"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="需求" prop="demand">
          <el-select v-model="ruleForm.demand" placeholder="请选择需求">
            <el-option label="商务合作" value="0"></el-option>
            <el-option label="成为区域代理" value="1"></el-option>
            <el-option label="成为经销商" value="2"></el-option>
            <el-option label="企业定制" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="真实姓名" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>

        <el-form-item label="身份证号码" prop="idcard">
          <el-input v-model="ruleForm.idcard"></el-input>
        </el-form-item>

        <el-form-item label="联系电话" prop="iphone">
          <el-input v-model="ruleForm.iphone"></el-input>
        </el-form-item>

        <el-form-item label="联系邮箱" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="ruleForm.remark"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showClose">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提 交</el-button
        >
      </span>
    </el-dialog>
    <img
      v-show="showScrollButton"
      src="../assets/gotop.png"
      alt=""
      class="bottom-right-image"
      @click="scrollToTop"
    />
  </div>
</template>

<script>
import { addbusiness } from '../api/business/cooperation'
import Contact from './Contact.vue'
import note from './note.vue'
import products from './products.vue'
import model from './business model.vue'
import Merchandising from './Merchandising.vue'
import cooperation from './cooperation.vue'

export default {
  components: {
    note,
    products,
    model,
    Contact,
    Merchandising,
    cooperation,
  },
  name: 'ZxHomeView',

  data() {
    return {
      lastScrollTop: 0,
      showScrollButton: false,
      ruleForm: {
        name: '',
        demand: '',
        idcard: '',
        phone: '',
        email: '',
        remark: '',
      },
      dialogVisible: false,
      activeTab: 'intro',
      show: false,
      rules: {
        name: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        demand: [{ required: true, message: '请选择需求', trigger: 'change' }],
        idcard: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const regIdcard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
              if (!value || regIdcard.test(value)) {
                callback()
              } else {
                callback(new Error('身份证号码格式不正确'))
              }
            },
            trigger: 'blur',
          },
        ],
        iphone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const regPhone = /^1[3456789]\d{9}$/
              if (!value || regPhone.test(value)) {
                callback()
              } else {
                callback(new Error('手机号码格式不正确'))
              }
            },
            trigger: 'blur',
          },
        ],
        email: [
          { required: true, message: '请输入联系邮箱', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const regEmail =
                /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
              if (!value || regEmail.test(value)) {
                callback()
              } else {
                callback(new Error('邮箱格式不正确'))
              }
            },
            trigger: 'blur',
          },
        ],
      },
    }
  },

  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.handleScroll)
    // this.scrollToTop()
  },
  beforeDestroy() {
    // 移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    //计算高度
    computewidth() {
      let screenwidth = document.body.clientWidth
      if (screenwidth > 1200) {
        return '30%'
      } else {
        return '90%'
      }
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // 使用平滑滚动效果
      })
      this.activeTab = 'intro'
    },
    handleScroll() {
      // const st = window.pageYOffset || document.documentElement.scrollTop
      // if (st > this.lastScrollTop) {
      //   // 向下滚动
      //   this.showScrollButton = true
      // } else {
      //   // 向上滚动
      //   this.showScrollButton = st === 0 ? false : true
      // }

      // this.lastScrollTop = st
      const st = window.pageYOffset || document.documentElement.scrollTop
      if (st > this.lastScrollTop) {
        // 向下滚动
        this.showScrollButton = true
      } else {
        // 向上滚动
        this.showScrollButton = st === 0 ? false : true
      }

      this.lastScrollTop = st

      // 检查当前滚动位置是否在某个特定区域内，并相应地设置 activeTab
      const sections = [
        'intro',
        'product',
        'business',
        'Contact',
        'Merchandising',
      ]
      sections.forEach(section => {
        const element = document.getElementById(section)
        if (element) {
          const elementRect = element.getBoundingClientRect()
          if (
            section === 'Merchandising' &&
            elementRect.top <= window.innerHeight / 2 &&
            elementRect.bottom >= window.innerHeight / 2
          ) {
            // 如果是“销售购买”部分可见，则将 activeTab 设置为“联系我们”
            this.activeTab = 'Contact'
          } else if (
            section === 'Contact' &&
            elementRect.top <= window.innerHeight / 2 &&
            elementRect.bottom >= window.innerHeight / 2
          ) {
            this.activeTab = section
            this.show = true
          } else {
            if (
              elementRect.top <= window.innerHeight / 2 &&
              elementRect.bottom >= window.innerHeight / 2
            ) {
              this.activeTab = section
            }
          }
        }
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs.ruleForm.clearValidate()
          this.dialogVisible = false
          this.ruleForm.name = ''
          this.ruleForm.demand = ''
          this.ruleForm.idcard = ''
          this.ruleForm.iphone = ''
          this.ruleForm.email = ''
          this.ruleForm.remark = ''
          done()
        })
        .catch(_ => {})
    },
    showClose() {
      this.$refs.ruleForm.clearValidate()
      this.dialogVisible = false
      this.ruleForm.name = ''
      this.ruleForm.demand = ''
      this.ruleForm.idcard = ''
      this.ruleForm.iphone = ''
      this.ruleForm.email = ''
      this.ruleForm.remark = ''
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 提交表单前的处理
          // ...

          // 调用后端接口
          addbusiness(this.ruleForm)
            .then(response => {
              // 后端返回数据的处理
              console.log(response)

              // 提示提交成功
              this.$message({
                showClose: true,
                message: '提交成功',
                type: 'success',
              })

              // 关闭对话框并清空表单
              this.dialogVisible = false
              this.ruleForm = ''
              //  window.location.reload()
            })
            .catch(error => {
              // 处理错误情况

              // 返回 false 表示提交失败
              return false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    scrollTo(section) {
      this.activeTab = section
      console.log(this.activeTab)

      if (this.activeTab == 'Contact') {
        console.log(111)
        this.show = !this.show
        return
      }

      const element = document.getElementById(section)
      if (element) {
        // 获取窗口高度
        const windowHeight =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight
        // 获取目标元素的位置
        const elementRect = element.getBoundingClientRect()
        // 计算滚动偏移量，使目标元素的顶部在页面中间
        const offset1 = elementRect.top - windowHeight / 5
        // 计算滚动偏移量，使目标元素的顶部在页面顶部
        // const offset = elementRect.top
        // 执行滚动
        window.scrollBy({
          top: offset1,
          behavior: 'smooth',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bottom-right-image {
  width: 30px;
  height: auto;
  position: fixed;
  bottom: 20px; /* 距离底部的距离 */
  right: 20px; /* 距离右侧的距离 */
  cursor: pointer;
}
.mine {
  background-color: #fff;
  position: absolute;
  margin-left: -20px;
  p {
    height: 50px;
  }
}
.home-view {
  width: 100%;
  height: 100px;
  background-color: #fff;
  display: flex;
  position: fixed;
  top: 0;
  flex-wrap: wrap;
  z-index: 999;
}
.logo {
  width: 500px;
}
.logoimg {
  float: left;
  width: 15%;
  margin-left: 50px;
  margin-top: 5%;
}
.title {
  line-height: 80px;
  font-size: 20px;
}

.tab {
  height: 90px;
  display: flex;
  margin: 0 auto;
  padding: 0px 10px;
  justify-content: space-around;
  line-height: 80px;
}
.tab-item {
  cursor: pointer;
  padding: 15px;
  margin: 0 5px;
  font-size: 18px;
}
.tab-item2 {
  cursor: pointer;
  padding: 15px;
  margin: 0 5px;
  font-size: 18px;
}
.tab-item2:hover,
.tab-item2.active {
  color: #f3cd78;
}
.tab-item:hover,
.tab-item.active {
  border-bottom: 3px solid #f3cd78;
}

.content-section {
  margin-top: 120px;
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .home-view {
    width: 100%;
    height: 250px;
    background-color: #fff;
  }
  .content-section {
    margin: 250px 0;
  }
  .logoimg {
    width: 30%;
    margin-left: 10%;
    margin-top: 5%;
  }

  .tab {
    width: 100%;
    height: 80px;
    margin-top: -30px;
  }
  .tab-item {
    cursor: pointer;
    padding: 5px;
    margin: 0 5px;
    font-size: 14px;
  }
  .tab-item2 {
    cursor: pointer;
    padding: 15px;
    margin: 0 5px;
    font-size: 14px;
  }
}
</style>
